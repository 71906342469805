
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import ContentTable from "@/components/reusable/policy-information/house-insurance/ContentTable.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import { useStore } from "vuex";
import "viewerjs/dist/viewer.css";

export default defineComponent({
  name: "contents-house-insurance-policy-details",
  components: { GoBackButton, ContentTable },
  data() {
    return {
      publicId: this.$route.params.publicId,
      policySummary: {},
      route: "",
      imageURL: ""
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Contents", [
        "Policy",
        "Policy Information",
        "House Insurance"
      ]);
    });
  },
  created() {
    const router = useRouter();
    const store = useStore();
    const userType = store.getters.currentUser;

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Policy images route
    this.imageURL = variables.POLICY_IMAGES_ROUTE;

    // Policy information route based on user roles
    if (userType.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
      this.route = variables.POLICIES_ROUTE + "/folio-item/" + this.publicId;
    } else if (userType.role == variables.BROKER_USER_ROLE) {
      // Broker role policy information route
      this.route = variables.BROKER_CLIENT_MOTOR_SCHEDULE_ROUTE + this.publicId;
    } else if (
      userType.role == variables.ADMIN_USER_ROLE ||
      userType.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      // Super Admin & Admin role policy information route
      this.route =
        variables.SUPERADMIN_CLIENT_MOTOR_SCHEDULE_ROUTE + this.publicId;
    }

    // Get the details of the vehicle in schdule list
    this.getRentedBuildingDetails();

    //Set page title
    document.title =
      "Contents House Insurance Policy Details | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getRentedBuildingDetails() {
      const router = useRouter();
      // Get contents policy details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.route)
          .then(({ data }) => {
            //Assign data to corresponding fields
            this.policySummary = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            router.push({ name: "client-policy" });
          });
      }
    }
  }
});
